@import './reset.css';

html,
body {
  background-color: #303030;
  color: #ffffff;
}
/* 
#app {
  min-height: 100vh;
  display: flex;
} */

h1 {
  font-family: 'Open Sans', sans-serif;
  font-size: 4rem;
  font-weight: 800;
  letter-spacing: -0.15rem;
}

h2 {
  font-family: 'Open Sans', sans-serif;
  font-weight: lighter;
  font-size: 2rem;
}

p {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
}

button {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.75rem;
  font-weight: bold;
  cursor: pointer;
  border: none;
  border-radius: 12px;
  background-color: rgb(49, 67, 76);
  color: white;
  padding: 0.5rem 1rem;
}

button:hover {
  background-color: rgb(41, 58, 67);
}
button:active {
  transform: translateY(1px);
}

.fb-retry-button {
  font-size: 1rem;
  font-weight: bold;
  padding: 0rem;
  background-color: transparent;
  text-decoration: underline;
  color: rgb(62, 82, 93);
}

.fb-retry-button:hover {
  background-color: transparent;
  color: rgb(41, 58, 67);
}

/* FB Specific (TODO move to a styling solution? */
.forge-bot-wallet-button {
  background-color: rgb(167, 0, 0) !important;
  border-radius: 12px !important;
}

.forge-bot-wallet-button:hover {
  background-color: rgb(106, 6, 6) !important;
}

.fb-info-panel {
  display: 'flex';
  height: 70vh;
  background-color: rgb(98, 118, 128);
  margin-right: 64px;
  margin-top: 100px;
  border-radius: 12px;
  padding: 36px 36px 12px 36px;
  overflow: auto;
  max-height: 600px;
}

.fb-info-blank {
  margin-right: 64px;
  margin-top: 100px;
}

.fb-loader-container {
  z-index: 1;
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

/* .wallet-adapter-button-trigger {
  background-color: rgb(167, 0, 0) !important;
} */
